@import "./styles/shared";
@import "./styles/reset";
@import "./styles/fonts";

*,
html,
body {
  box-sizing: border-box;
}

// Prevent mobile browsers from randomly
// changing font sizes when they feel like it
html {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

html,
body {
  @include defaulttypo;
  -webkit-font-smoothing: antialiased;
}

body {
  @include theme-background-color(c_backdrop);
  &.overlayOpen {
    pointer-events: none;
  }
}

.Main {
  // @include defaulttypo;
}

.Bold {
  @include theme-font-weight(fw_bold);
}

.buc-text-explanation {
  // IMPORTANT! Should never be displayed
  font-size: 0;
}
