@import "styles/shared";

.Task-option {
  display: block;
  position: relative;
  border: 2px solid transparent;
  @include theme-border-color(c_border-inverse);
  @include theme-border-radius(ra_default);
  @include theme-background-color(c_section-inverse-bg);
  padding: $m_default $m_gutter_default * 2.5 $m_default $m_gutter_default;
  //@include theme-font-size(s_medium);
  font-size: 14px;
  line-height: $lh_small;

  @media all and (min-width: $delta_query) {
    padding: $m_medium_large $m_gutter_large * 2.5 $m_medium_large $m_gutter_large;
  }
  cursor: pointer;
  &__element {
    position: absolute;
    left: -999em;
  }

  &__image {
    height: 60px;
    margin: 0 auto $m_small;
    text-align: center;
    @media all and (min-width: $delta_query) {
      height: 96px;
      margin: 0 auto $m_medium_large;
    }

    img {
      @include image;
      height: 100%;
    }
  }
  &__text {
    word-wrap: break-word;
  }

  @keyframes blinking {
    0% {
      outline-color: transparent;
    }
    100% {
      outline-color: $c_buc_cleargreen;
    }
  }

  &.Task-option--show-correct {
    outline: 5px solid transparent;
    outline-offset: 2px;
    animation: blinking 700ms infinite;
  }

  &--isselected {
    @include theme-background-color(c_progress);
    @include theme-border-color(c_border-selected);
    .Task-option__text {
      @include theme-font-weight(fw_medium);
    }
    &.Task-option--incorrect {
      @include theme-background-color(c_incorrect-answer-bg);
      @include theme-border-color(c_incorrect-answer-border);
    }
    &.Task-option--correct {
      @include theme-background-color(c_correct-answer-bg);
      @include theme-border-color(c_correct-answer-border);
    }
  }

  &--hasimg {
    padding: $m_large $m_default;
    text-align: center;
    @include theme-font-size(s_medium_small);
    line-height: $lh_xsmall;
    @media all and (min-width: $delta_query) {
      @include theme-font-size(s_medium_large);
    }

    flex: 1 0 auto; // Same height
    .Task-option__icon {
      display: none;
    }
  }

  // Only in debug mode, show if correct
  &--dc {
    &:after {
      content: " ";
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      width: 10px;
      bottom: 0;
      @include theme-background-color(c_success, true);
    }
  }
}
