@import "styles/shared";

.Task-interaction-image {
  margin-bottom: $m_medium;
  
  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $m_medium;
  }
}
