@import "styles/shared";

.Course-diploma {
  margin-bottom: $m_default;
  border-bottom: 3px solid transparent;
  @include theme-border-color(c_border-section);
  &__coursename {
    @include heading(2);
    padding: $m_xlarge $m_gutter_default;
    @include gutters;
    @include theme-background-color(c_success);
    @include theme-color(c_inverse-text-emph);
  }
  &__inner {
    padding: $m_xlarge $m_gutter_default;
    @include gutters;
  }
  &__confirmationheading {
    @include heading(2);
    display: inline-block;
    border-bottom: 3px solid transparent;
    @include theme-border-color(c_success);
    padding-bottom: $m_default;
    margin-bottom: $m_default;
  }
  &__confirmationtext + &__confirmationtext {
    margin-top: $m_default;
  }
  &__confirmationtext,
  &__informationtext {
    @include theme-font-size(s_medium);
    line-height: $lh_medium;
    @include theme-color(c_faded);
    max-width: $w_general-copy-maxwidth;
    @media all and (min-width: $delta_query) {
      @include theme-font-size(s_medium_large);
    }
  }

  &__informationtext {
    @include theme-font-weight(fw_bold);
    margin-bottom: $m_default;
  }

  &__sct-actions {
    &__button-row {
      display: flex;
      flex-direction: column;
      margin-top: $m_gutter_xlarge;
      @media all and (min-width: $beta_query) {
        flex-direction: row;
      }
    }
    &__button {
      &--certificate {
        margin-bottom: $m_default;
        @include button($blocked: true, $type: "inverse", $extraHeight: true);
        @media all and (min-width: $beta_query) {
          margin-right: $m_default;
          margin-bottom: 0;
        }
      }
      &--course {
        @include button($blocked: true, $type: "secondary", $extraHeight: true);
      }
    }
  }
  &__actions {
    margin: $m_large -#{calc($m_gutter_default / 2)} 0;
    display: flex;
    flex-wrap: wrap;
    @media all and (min-width: $beta_query) {
      margin: $m_large -#{calc($m_gutter_large / 2)} 0;
    }
  }
  &__action {
    // flex: 1 0 calc(50% - #{$m_gutter_default/2});
    // width: calc(50% - #{$m_gutter_default/2});
    // margin: 0 $m_gutter_default/4;
    flex: 1 0 50%;
    width: 50%;
    max-width: 50%;

    padding: 0 calc($m_gutter_default / 2);
    position: relative;
    margin-bottom: $m_default;
    @media all and (min-width: $beta_query) {
      flex: 1 0 25%;
      width: 25%;
      padding: 0 calc($m_gutter_large / 2);
      margin-bottom: $m_large;
    }
    &:before {
      content: "";
      float: left;
      padding-top: 100%; /* initial ratio of 1:1*/
    }
  }
  &__actioninner {
    position: absolute;
    @include theme-background-color(c_success-bg);
    margin: 0 calc($m_gutter_default / 2);
    padding: $m_gutter_default;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__actiontext {
    @include theme-font-size(s_medium_small);
    line-height: $lh_xsmall;
    @include theme-color(c_body-text);
    @include theme-font-weight(fw_medium);
  }
  &__actionicon {
    margin-bottom: $m_small;
    svg {
      path,
      circle,
      rect {
        @include theme-fill(c_body-bg);
        &.fauxtransparent {
          @include theme-fill(c_success-bg);
        }
        &.highlight {
          @include theme-fill(c_success);
        }
      }
    }
    &--key {
    }
    &--feedback {
    }
  }
  &__disclaimer {
    padding-top: $m_default;
    @include theme-color(c_more-faded);
    line-height: $lh_medium;
    @include theme-font-size(s_medium_small);
    max-width: 500px;
  }
}
