@import "styles/shared";

.Task-interaction-order {
  display: grid;
  grid-template-columns: min-content auto;
  gap: $m_medium;
  margin-bottom: $m_medium;

  &__list {
    display: grid;
    gap: $m_medium;
  }
  &__order-listitem {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: 100%;
    background-color: #3f4a4f;
    font-size: 24px;
    font-weight: 700;
    color: white;
    padding: .5rem;
  }

  &__listitem {
    display: flex;
    position: relative;
    width: 100%;
    height: fit-content;
    @include theme-background-color(c_section-inverse-bg);
    transition: background-color 0.5s ease;
    border: 2px solid transparent;
    @include theme-border-color(c_border-answer);

    &.--correct {
      @include theme-background-color(c_correct-answer-bg);
      @include theme-border-color(c_correct-answer-border);
    }

    &.--incorrect {
      @include theme-background-color(c_incorrect-answer-bg);
      @include theme-border-color(c_incorrect-answer-border);
    }

    &-text {
      font-size: $lh_xsmall;
      line-height: $lh_xsmall;
      @include theme-font-weight(fw_medium);
      padding: $m_medium_large;
      @include theme-border-color(c_more-faded);
      width: 100%;

      /*  @media all and (min-width: $beta_query) {
        @include theme-font-size(s_medium_large);
        padding: $m_medium_large;
      } */
      .right-answer {
        @include theme-font-weight(fw_demi-new);
      }
    }

    &-debug {
      position: absolute;
      left: 60%;
      top: calc(50% - (1.5em / 2));
      @include theme-color(c_success);
      @include theme-background-color(c_body-bg);
      display: inline-block;
      min-width: 1.5em;
      text-align: center;
      &.--show-answer {
        font-size: 32px;
      }
    }
  }
}
