@import "styles/shared";


.Task-interaction-slider {
  margin-bottom: $m_medium;
  padding: $m_xxlarge 0 $m_large;
  @include gutters;
  @include theme-background-color(c_section-inverse-bg);
  &__image {
    height: 80px;
    margin: 0 auto $m_default;
    text-align: center;
    @media all and (min-width: $beta_query) {
      height: 120px;
      margin: 0 auto $m_large;
    }
    @media all and (min-width: $delta_query) {
      
      height: 144px;
    }
    img {
      @include image;
      height: 100%;
      width: auto;
    }
  }
  &__text {
    @include theme-font-size(s_medium);
    line-height: $lh_small;
    @include theme-font-weight(fw_bold);
    text-align: center;
    margin-bottom: $m_default;
    @media all and (min-width: $beta_query) {
      margin-bottom: $m_large;
    }
  }
  

  
}
