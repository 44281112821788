@mixin numberedlist {
  margin: $m_default 0;
  list-style: decimal outside none;
  padding-left: $m_large;
  @media all and (min-width: $beta_query) {
    padding-left: 35px;
  }
  @media all and (min-width: $delta_query) {
    margin-left: $m_default;
  }
  @include generalcopymaxwidth;
  li + li {
    margin-top: calc($m_default / 2);
    @media all and (min-width: $beta_query) {
      margin-top: $m_default;
    }
  }
}
