@import "styles/shared";

.Task-header {
  &__heading {
    @include overlayHeading;
  }
  &__sound {
    padding-top: $m_small;
    @media all and (min-width: $beta_query) {
      padding-top: $m_default;
    }

    // margin-bottom: $m_default;
    // @media all and (min-width: $beta_query) {
    //   margin-bottom: $m_large;
    // }
  }
  &__main-content {
    @include theme-font-size(s_medium);
    line-height: $lh_small;
    @include theme-font-weight(fw_medium);
    margin-bottom: $m_default;
    @media all and (min-width: $beta_query) {
      @include theme-font-size(s_medium_large);
    }
  }
  &__content {
    @include theme-font-size(s_medium);
    line-height: $lh_small;
  }
  &__instruction {
    margin-top: $m_large;
    @include theme-font-size(s_medium_small);
    line-height: $lh_medium;
    padding-bottom: $m_default;
    @include keeptextmedium;
  }
  &__chapter {
    @include theme-font-weight(fw_bold);
  }
  &--isInFacts {
    .Task-header__instruction {
      margin-top: $m_default;
    }
  }
}
