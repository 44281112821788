@import "styles/shared";

.Task-interaction-match {
  &__puzzle {
    display: flex;
  }

  &__statements,
  &__answers {
    flex: 1;
  }

  &-options {
    display: flex;
    margin-bottom: 2rem;
    gap: 1rem;
    flex-wrap: wrap;
  }

  &__statement {
    @include card($bg_color: c_button-default-bg, $c_text: c_button-primary-text);

    &__text {
      display: -webkit-inline-box;
      color: inherit;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      white-space: normal;

      @include theme-font-weight(fw_bold-new);
    }

    &--correct {
      @include card(
        $bg_color: c_correct-answer-bg,
        $c_text: c_button-primary-text,
        $border_color: c_correct-answer-border
      );
      border-right-width: 1px !important;
    }
    &--incorrect {
      @include card(
        $bg_color: c_incorrect-answer-bg,
        $c_text: c_button-primary-text,
        $border_color: c_incorrect-answer-border
      );
      flex-direction: column;
      border-right-width: 1px !important;

      .right-answer {
        @include theme-font-weight(fw_demi-new);
      }
    }
  }
}
