/**
 *
 * Colors
 *
**/

// -- NOTE: Color variable names are
// Bohman's associations and may not make sense :)

// Direct colors for Byggföretagen theme - do not use directly
$c_byggforetagen_black: #293034;
$c_byggforetagen_almostblack: #344147;
$c_byggforetagen_grey-dark-1: #3f4a4f;
$c_byggforetagen_grey-medium: #4e5c62;
$c_byggforetagen_grey-light: #5f6d73;
$c_byggforetagen_grey-blue: #adc0c9;
$c_byggforetagen_grey-lighter: #bfcad0;
$c_byggforetagen_white: #fff;
$c_byggforetagen_offwhite: #f6f8f9;
$c_byggforetagen_yellow: #fffc4d;
$c_byggforetagen_skyblue: #90dafd;
$c_byggforetagen_lime: #8ffe58;
$c_byggforetagen_moss: #63744b;
$c_byggforetagen_sunburn: #ffbfb7;

$c_byggforetagen_fasttrack_menu: #5c6d74;

// Direct colors for BUC theme - do not use directly
$c_buc_black: #293034; // TODO?
$c_buc_white: #fff;
$c_buc_offwhite: #f8f8f8;
$c_buc_dirt-dark-1: #2d322b;
$c_buc_dirt-dark-2: #3f423d;
$c_buc_dirt-medium: #454c41;
$c_buc_grey-light: #d8d8d8;

$c_buc_cleargreen: #549c3c;
$c_buc_yellow: #fbf142;
$c_buc_sunburn: #ffbfb7;
$c_buc_babyblue: #acdef5;
$c_buc_springleaf: #9ff176;
$c_buc_plankton: #455740;

// Direct colors for Arrangörsutbildningar theme - do not use directly
$c_arrangorsutbildningar_purple: #4d5f6e;

// shared colors
$c_misq_cleargreen: #58a342;
$c_misq_lightgreen: #deecb8;
$c_misq_pink: #ffdbd6;
$c_misq_crayfish: #ff634f;
$c_misq_empty: #f0f0f0;
$c_misq_blue: #51a8d5;
/* svg > path {
/**
 *
 * Font fams
 *
**/

$ff_circular: "CircularWeb", sans-serif;
$ff_urwdin: "urw-din", sans-serif;
$ff_sans-serif: sans-serif;

/**
 *
 * Rounded corners
 *
**/

$ra_small: 2px;
$ra_large: 3px;
$ra_xlarge: 70px;
$ra_round: 50%;
$ra_unlimited: 999px;
$ra_default: $ra_small;

/**
 *
 * Font weights
 *
**/
$fw_light: 300;
$fw_normal: 400;
$fw_medium: 500;
$fw_demi: 600;
$fw_bold: 700;
$fw_black: 900;

$theme-name_byggforetagen: "byggforetagen";
$theme-name_buc: "buc";
$theme-name_arrangorsutbildningar: "arrangorsutbildningar";

$THEMES: (
  $theme-name_byggforetagen: (
    /* Contextual colors */ c_action-primary: $c_byggforetagen_yellow,
    c_sound-action: $c_misq_crayfish,
    c_body-bg: $c_byggforetagen_grey-dark-1,
    c_body-bg-transp: rgba($c_byggforetagen_grey-dark-1, 0.75),
    c_body-bg-darker: $c_byggforetagen_almostblack,
    c_body_bg_empty: $c_misq_empty,
    c_body-text: $c_byggforetagen_white,
    c_body-text-neutral: $c_byggforetagen_grey-light,
    c_button-default-bg: $c_byggforetagen_white,
    c_button-default-bg-disabled: rgba($c_byggforetagen_white, 0.5),
    c_button-default-text: $c_byggforetagen_grey-dark-1,
    c_button-default-text-disabled: rgba($c_byggforetagen_grey-dark-1, 0.5),
    c_button-primary-bg: $c_byggforetagen_yellow,
    c_button-primary-bg-disabled: rgba($c_byggforetagen_yellow, 0.5),
    c_button-primary-text: $c_byggforetagen_grey-dark-1,
    c_button-primary-text-disabled: rgba($c_byggforetagen_grey-dark-1, 0.5),
    c_button-inverse-bg: $c_byggforetagen_grey-medium,
    c_button-inverse-bg-disabled: rgba($c_byggforetagen_grey-medium, 0.5),
    c_button-inverse-text: $c_byggforetagen_yellow,
    c_inverse-text: $c_byggforetagen_grey-medium,
    c_inverse-text-emph: $c_byggforetagen_grey-dark-1,
    c_inverse-bg: $c_byggforetagen_offwhite,
    c_inverse-bg-faded: rgba($c_byggforetagen_offwhite, 0.5),
    c_inverse-heading-border: $c_byggforetagen_grey-medium,
    c_progress: $c_buc_babyblue,
    c_progress-faded: rgba($c_buc_babyblue, 0.5),
    c_progress-inverse: $c_buc_babyblue,
    c_progress-inverse-faded: rgba($c_buc_babyblue, 0.5),
    c_faded: rgba($c_byggforetagen_white, 0.87),
    c_more-faded: $c_byggforetagen_grey-blue,
    c_success: $c_byggforetagen_lime,
    c_success-faded: rgba($c_byggforetagen_lime, 0.5),
    c_success-bg: $c_byggforetagen_moss,
    c_fail: $c_misq_crayfish,
    c_fail-bg: $c_byggforetagen_sunburn,
    c_section-bg: $c_byggforetagen_grey-medium,
    c_section-inverse-bg: $c_byggforetagen_white,
    c_border-section: $c_byggforetagen_grey-light,
    c_border-inverse: $c_byggforetagen_grey-medium,
    c_border-neutral: $c_byggforetagen_grey-light,
    c_border-neutral-hovered: $c_misq_blue,
    c_border-selected: $c_misq_blue,
    c_border_empty: $c_misq_empty,
    c_border-answer: $c_byggforetagen_grey-dark-1,
    c_delimiter-bg: $c_byggforetagen_grey-light,
    c_loading: $c_byggforetagen_white,
    c_shadow: rgba($c_byggforetagen_black, 0.82),
    c_shadow-weak: rgba($c_byggforetagen_black, 0.08),
    c_backdrop: $c_byggforetagen_grey-lighter,
    c_translucent-backdrop: rgba($c_byggforetagen_black, 0.5),
    c_link: $c_byggforetagen_yellow,
    c_link-inverse: $c_misq_crayfish,
    c_oddrow: $c_byggforetagen_grey-light,
    c_oddrow-inverse: rgba($c_byggforetagen_grey-lighter, 0.3),
    c_correct-answer-border: $c_misq_cleargreen,
    c_correct-answer-bg: $c_misq_lightgreen,
    c_incorrect-answer-border: $c_misq_crayfish,
    c_incorrect-answer-bg: $c_misq_pink,
    /* Font fams */ ff_default: $ff_circular,
    /* Border radius */ ra_default: $ra_default,
    ra_buttons: $ra_small,
    /* Font weights */ fw_light: $fw_normal,
    fw_normal: $fw_normal,
    fw_medium: $fw_medium,
    fw_bold-new: $fw_demi,
    fw_demi-new: $fw_bold,
    fw_bold: $fw_bold,
    fw_demi: $fw_demi,
    fw_black: $fw_black,
    fw_prominentheadings: $fw_black,
    /* Font sizes */ s_small: 12px,
    s_medium_small: 14px,
    s_medium: 16px,
    s_medium_large: 21px,
    s_large: 24px,
    s_xlarge: 33px,
    s_xxlarge: 48px,
    s_default: 12px
  ),
  $theme-name_buc: (
    /* Contextual colors */ c_action-primary: $c_buc_yellow,
    c_sound-action: $c_misq_crayfish,
    c_body-bg: $c_buc_dirt-dark-1,
    c_body-bg-transp: rgba($c_buc_dirt-dark-1, 0.75),
    /* TODO */ c_body-bg-darker: darken($c_buc_dirt-dark-1, 10%),
    c_body_bg_empty: $c_misq_empty,
    c_body-text: $c_byggforetagen_white,
    c_body-text-neutral: $c_byggforetagen_grey-light,
    c_button-default-bg: $c_buc_white,
    c_button-default-bg-disabled: rgba($c_buc_white, 0.5),
    c_button-default-text: $c_buc_dirt-medium,
    c_button-default-text-disabled: rgba($c_buc_dirt-medium, 0.5),
    c_button-primary-bg: $c_buc_yellow,
    c_button-primary-bg-disabled: rgba($c_buc_yellow, 0.5),
    c_button-primary-text: $c_buc_dirt-dark-1,
    c_button-primary-text-disabled: rgba($c_buc_dirt-dark-1, 0.5),
    c_button-inverse-bg: $c_buc_cleargreen,
    c_button-inverse-bg-disabled: rgba($c_buc_cleargreen, 0.5),
    c_button-inverse-text: $c_buc_white,
    c_inverse-text: $c_buc_dirt-dark-1,
    /* TODO*/ c_inverse-text-emph: $c_buc_dirt-dark-1,
    c_inverse-bg: $c_buc_offwhite,
    /* TODO*/ c_inverse-bg-faded: rgba($c_buc_offwhite, 0.5),
    c_inverse-heading-border: $c_buc_grey-light,
    c_progress: $c_buc_babyblue,
    c_progress-faded: rgba($c_buc_babyblue, 0.65),
    c_progress-inverse: $c_buc_babyblue,
    c_progress-inverse-faded: rgba($c_buc_babyblue, 0.65),
    c_faded: rgba($c_buc_white, 0.87),
    /* TODO*/ c_more-faded: rgba($c_buc_white, 0.87),
    c_success: $c_buc_springleaf,
    c_success-faded: rgba($c_buc_springleaf, 0.5),
    c_success-bg: $c_buc_plankton,
    c_fail: $c_misq_crayfish,
    c_fail-bg: $c_buc_sunburn,
    c_section-bg: $c_buc_dirt-medium,
    c_section-inverse-bg: $c_buc_white,
    c_border-section: $c_buc_dirt-dark-2,
    c_border-inverse: $c_buc_dirt-medium,
    //TODO: check what colors we should use
    c_border-neutral: $c_byggforetagen_grey-light,
    c_border-neutral-hovered: $c_misq_blue,
    c_border-selected: $c_misq_blue,
    c_border_empty: $c_misq_empty,
    c_border-answer: $c_buc_dirt-dark-1,

    c_delimiter-bg: $c_buc_dirt-dark-1,
    c_loading: $c_buc_white,
    c_shadow: rgba($c_buc_black, 0.82),
    c_shadow-weak: rgba($c_byggforetagen_black, 0.08),
    /* TODO */ c_backdrop: $c_byggforetagen_grey-lighter,
    c_translucent-backdrop: rgba($c_buc_black, 0.5),
    c_link: $c_byggforetagen_white,
    c_link-inverse: $c_misq_crayfish,
    c_oddrow: $c_buc_dirt-dark-2,
    c_oddrow-inverse: rgba($c_buc_grey-light, 0.5),
    c_correct-answer-border: $c_misq_cleargreen,
    c_correct-answer-bg: $c_misq_lightgreen,
    c_incorrect-answer-border: $c_misq_crayfish,
    c_incorrect-answer-bg: $c_misq_pink,
    /* Font fams */ ff_default: $ff_urwdin,
    /* Border radius */ ra_default: $ra_default,
    ra_buttons: $ra_unlimited,
    /* Font weights */ fw_light: $fw_light,
    fw_normal: $fw_normal,
    fw_medium: $fw_medium,
    fw_bold-new: $fw_medium,
    fw_demi-new: $fw_demi,
    fw_bold: $fw_demi,
    fw_black: $fw_demi,
    fw_prominentheadings: $fw_light,
    /* Font sizes */ s_small: 13px,
    s_medium_small: 13px,
    s_medium: 16px,
    s_medium_large: 21px,
    s_large: 27px,
    s_xlarge: 36px,
    s_xxlarge: 48px,
    s_default: 13px
  ),
  $theme-name_arrangorsutbildningar: (
    /* Contextual colors */ c_action-primary: $c_byggforetagen_yellow,
    c_sound-action: $c_misq_crayfish,
    c_body-bg: $c_byggforetagen_grey-dark-1,
    c_body-bg-transp: rgba($c_byggforetagen_grey-dark-1, 0.75),
    c_body-bg-darker: $c_byggforetagen_almostblack,
    c_body_bg_empty: $c_misq_empty,
    c_body-text: $c_byggforetagen_white,
    c_body-text-neutral: $c_byggforetagen_grey-light,
    c_button-default-bg: $c_byggforetagen_white,
    c_button-default-bg-disabled: rgba($c_byggforetagen_white, 0.5),
    c_button-default-text: $c_byggforetagen_grey-dark-1,
    c_button-default-text-disabled: rgba($c_byggforetagen_grey-dark-1, 0.5),
    c_button-primary-bg: $c_byggforetagen_yellow,
    c_button-primary-bg-disabled: rgba($c_byggforetagen_yellow, 0.5),
    c_button-primary-text: $c_byggforetagen_grey-dark-1,
    c_button-primary-text-disabled: rgba($c_byggforetagen_grey-dark-1, 0.5),
    c_button-inverse-bg: $c_byggforetagen_grey-medium,
    c_button-inverse-bg-disabled: rgba($c_byggforetagen_grey-medium, 0.5),
    c_button-inverse-text: $c_byggforetagen_yellow,
    c_inverse-text: $c_byggforetagen_grey-medium,
    c_inverse-text-emph: $c_byggforetagen_grey-dark-1,
    c_inverse-bg: $c_byggforetagen_offwhite,
    c_inverse-bg-faded: rgba($c_byggforetagen_offwhite, 0.5),
    c_inverse-heading-border: $c_byggforetagen_grey-medium,
    c_progress: $c_buc_babyblue,
    c_progress-faded: rgba($c_buc_babyblue, 0.5),
    c_progress-inverse: $c_buc_babyblue,
    c_progress-inverse-faded: rgba($c_buc_babyblue, 0.5),
    c_faded: rgba($c_byggforetagen_white, 0.87),
    c_more-faded: $c_byggforetagen_grey-blue,
    c_success: $c_byggforetagen_lime,
    c_success-faded: rgba($c_byggforetagen_lime, 0.5),
    c_success-bg: $c_byggforetagen_moss,
    c_fail: $c_misq_crayfish,
    c_fail-bg: $c_byggforetagen_sunburn,
    c_section-bg: $c_arrangorsutbildningar_purple,
    c_section-inverse-bg: $c_byggforetagen_white,
    c_border-section: $c_byggforetagen_grey-light,
    c_border-inverse: $c_byggforetagen_grey-medium,
    //TODO: check what colors we should use
    c_border-neutral: $c_byggforetagen_grey-light,
    c_border-neutral-hovered: $c_misq_blue,
    c_border_empty: $c_misq_empty,
    c_border-answer: $c_byggforetagen_grey-dark-1,
    c_delimiter-bg: $c_byggforetagen_grey-light,
    c_loading: $c_byggforetagen_white,
    c_shadow: rgba($c_byggforetagen_black, 0.82),
    c_shadow-weak: rgba($c_byggforetagen_black, 0.08),
    c_backdrop: $c_byggforetagen_grey-lighter,
    c_translucent-backdrop: rgba($c_byggforetagen_black, 0.5),
    c_link: $c_byggforetagen_yellow,
    c_link-inverse: $c_misq_crayfish,
    c_oddrow: $c_byggforetagen_grey-light,
    c_oddrow-inverse: rgba($c_byggforetagen_grey-lighter, 0.3),
    c_correct-answer-border: $c_misq_cleargreen,
    c_correct-answer-bg: $c_misq_lightgreen,
    c_incorrect-answer-border: $c_misq_crayfish,
    c_incorrect-answer-bg: $c_misq_pink,
    /* Font fams */ ff_default: $ff_circular,
    /* Border radius */ ra_default: $ra_default,
    ra_buttons: $ra_small,
    /* Font weights */ fw_light: $fw_normal,
    fw_normal: $fw_normal,
    fw_medium: $fw_medium,
    fw_bold: $fw_bold,
    fw_black: $fw_black,
    fw_prominentheadings: $fw_black,
    /* Font sizes */ s_small: 12px,
    s_medium_small: 14px,
    s_medium: 16px,
    s_medium_large: 21px,
    s_large: 24px,
    s_xlarge: 33px,
    s_xxlarge: 48px,
    s_default: 12px
  )
) !default;

/**
 *
 * Breakpoints
 *
**/
$beta_query: 768px;
$charlie_query: 1024px;
$delta_query: 1280px;
$echo_query: 1440px;

/**
 *
 * Margins
 *
**/

$m_xsmall: 4px;
$m_small: 8px;

$m_medium: 12px;
$m_medium_large: 18px;
$m_large: 24px;
$m_xlarge: 36px;
$m_xxlarge: 48px;
$m_xxxlarge: 72px;
$m_default: $m_medium;
$m_gutter_default: $m_medium;
$m_gutter_large: $m_large;
$m_gutter_xlarge: $m_xlarge;

/**
 *
 * Line height
 *
**/

$lh_xxsmall: 0.88;
$lh_xsmall: 1.14;
$lh_small: 1.31;
$lh_medium: 1.5;
$lh_large: 2;
$lh_default: $lh_medium;

/**
 *
 * Heights
 *
**/

$h_breadcrumb: 82px;
$h_breadcrumb-beta: 118px;
$h_button: 48px;
$h_task-match-option-card: 56px;
$h_repetition-textarea: 165px;
$h_user-menu: 72px;
$h_user-menu-mobile: 52px;

/**
 *
 * Scale
 *
**/
$sc_initial-hidden: 0.7;

/**
 *
 * Transitions
 *
**/
$tr_default-speed: 0.25s;

/**
 *
 * Widths
 *
**/
$w_menu-width: 375px;
$w_main-maxwidth: 768px;
$w_general-copy-maxwidth: 576px;
$w_resource-page-maxwidth: 1024px;
$w_general-copy-maxwidth-large: $w_main-maxwidth;
$w_total-maxwidth: 1440px;

/**
 *
 * Gradients
 *
 *
**/
$gr_buc_chapter_intro: linear-gradient(to bottom, #2d461f 21%, #3c7c31 91%);
$gr_byggforetagen_headerfade: linear-gradient(
  90deg,
  rgba($c_byggforetagen_white, 0) 0%,
  rgba($c_byggforetagen_grey-dark-1, 1) 65%
);
$gr_buc_headerfade: linear-gradient(
  90deg,
  rgba($c_byggforetagen_white, 0) 0%,
  rgba($c_buc_dirt-dark-1, 1) 65%
);
$gr_byggforetagen_carouselfade: linear-gradient(
  to left,
  rgba($c_byggforetagen_offwhite, 0.74) 25%,
  rgba($c_byggforetagen_offwhite, 0)
);
$gr_buc_carouselfade: linear-gradient(
  to left,
  rgba($c_buc_offwhite, 0.74) 25%,
  rgba($c_buc_offwhite, 0)
);
