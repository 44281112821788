@mixin card(
  $bg_color: c_body-bg,
  $c_text: c_body-text,
  $borderStyle: solid,
  $border_color: c_border-answer
) {
  display: flex;
  align-items: center;
  justify-content: center;

  @include theme-background-color($bg_color);
  //@include theme-font-size(s_medium_small);
  font-size: 14px;
  letter-spacing: 0.2px;

  @include theme-color($c_text);
  line-height: $lh_medium; //1.5em
  hyphens: auto;
  -webkit-hyphenate-character: auto;
  -moz-hyphens: auto;
  height: $h_task-match-option-card;
  border-width: 2px;
  border-style: $borderStyle;
  @include theme-border-color($border_color);
  margin-bottom: $m_default;
  transition: background-color 0.5s ease;
  span {
    @include theme-font-weight(fw_medium);
    text-align: center;
  }
}
