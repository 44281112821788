@import "styles/shared";

.Summary-header {
  padding: $m_large $m_gutter_default;
  @include gutters;
  @include theme-background-color(c_progress);

  &--isSuccess {
    @include theme-background-color(c_success);
  }

  &__preheading {
    @include theme-color(c_inverse-text-emph);
  }
  &__afterheading {
    @include theme-color(c_inverse-text-emph);
    @include theme-font-size(s_small);
    padding-top: $m_small;
  }
  &__heading {
    @include heading(3);
    @media all and (min-width: $beta_query) {
      @include heading(2);
    }
    @include theme-color(c_inverse-text-emph);
  }
}
