@import "styles/shared";

.Task-interaction-match-option-card {
  @include card();
  width: 31.9%;
  margin-bottom: 0;

  &--puzzled {
    width: initial;
    margin-bottom: $m_default;
  }

  &--active {
    @include card($border_color: c_border-neutral-hovered);
  }

  &--correct {
    @include card(
      $bg_color: c_correct-answer-bg,
      $c_text: c_button-primary-text,
      $border_color: c_correct-answer-border
    );
    border-left-width: 1px !important;
  }
  &--incorrect {
    @include card(
      $bg_color: c_incorrect-answer-bg,
      $c_text: c_button-primary-text,
      $border_color: c_incorrect-answer-border
    );
    border-left-width: 1px !important;
  }

  &__neutral {
    @include card($bg_color: c_body_bg_empty, $border_color: c_border_empty);
    width: 31.9%;
    margin-bottom: 0;
  }

  &__slot {
    @include card(
      $bg_color: c_button-default-bg,
      $borderStyle: dotted,
      $c_text: c_body-text-neutral,
      $border_color: c_border-neutral
    );
    border-left-color: transparent !important;

    &--hovered {
      @include card(
        $bg_color: c_button-default-bg,
        $borderStyle: solid,
        $c_text: c_body-text-neutral,
        $border_color: c_border-neutral-hovered
      );
    }
  }
}
